<template>
  <div class="flex h-full bg-cream">
    <div class="m-auto w-full">
      <div class="flex items-start justify-center space-x-10">
        <BackButton class="mt-3" @onClick="goBack"/>
        <div class="flex flex-col text-lg mb-2 w-100">
          <p class="heading-l-500 mb-5 capitalize">{{ closeJobText }} job</p>
          <p class="body-l-400">Are you sure you want to {{ closeJobText }} this job? This action cannot be undone.</p>

          <p v-if="showChurchProratedAmount" class="body-l-400 mt-4">
            A prorated amount of <b>{{ proratedAmount || currency }}</b> will be charged today.
          </p>
          <p v-if="showProOneTimeJobWarnings">
            If you quit this job, you will not be able to complete the job or receive payment for it.
          </p>
          <p v-else-if="showProRecurringJobWarnings">
            If you quit, you will no longer receive monthly payments for this job.
          </p>

          <ButtonIcon
            :text="confirmButtonText"
            font-weight="font-normal"
            x-padding="px-4"
            :loading="loading"
            :disabled="loading"
            class="w-full mt-16"
            @onClick="endJob"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapMutations, mapState } from 'vuex'
import BackButton from "@/components/shared/buttons/BackButton";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: 'EndJobModal',
  components: { BackButton, ButtonIcon },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      job: state => state.jobs.current,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    closeJobText() {
      return this.userIsPro ? 'quit' : 'end';
    },

    proratedAmount() {
      return this.job.prorated_amount;
    },

    showChurchProratedAmount(){
      return !this.userIsPro && this.job.type == 'recurring' && this.proratedAmount && this.proratedAmount > 0;
    },

    showProOneTimeJobWarnings(){
      return this.userIsPro && this.job.type == 'one_time';
    },

    showProRecurringJobWarnings(){
      return this.userIsPro && this.job.type == 'recurring';
    },

    confirmButtonText(){
      let text = this.userIsPro ? 'Quit' : 'End';
      text += ' the job';
      if (this.proratedAmount && this.proratedAmount > 0) {
        text += ` and pay $${this.proratedAmount} today`;
      }
      return text;
    },
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
      updateItem: "home/updateItem",
      showModal: 'general/showModal',
      hideModal: 'general/hideModal',
    }),

    async endJob() {
      this.loading = true;
      try {
        const { data } = await api.post(`jobs/${this.job.id}/close`);
        if (data.success) {
            this.setJob(data.data);
            this.updateItem({ id: this.job.id, data: { status: 'closed' } });
            this.$router.push('/');
            this.hideModal();
        }
      } catch (error) {
        console.log(error);
        this.$toast.error('There was an error. Try again later.');
      } finally {
        this.loading = false;
      }
    },

    goBack() {
      this.showModal({ componentName: 'JobModal', props: { jobId: this.job.id }});
    },
  },
}
</script>